import { createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import ApiClientConnector from "src/api/ApiClientConnector";

const initialState = {
    reservationSummaryList: [],
    reservationId: ''
};

export const ReservationsManagerSlice = createSlice({
    name: 'reservations',
    initialState,
    reducers: {
        getReservationSummaryList: (state, action) => {
            state.reservationSummaryList = action.payload;
        },
        setReservationId: (state, action) => {
            state.reservationId = action.payload;
        }
    }
})

export const { getReservationSummaryList, setReservationId } = ReservationsManagerSlice.actions;

export const fetchReservationSummaryList = (instance, accounts, criteria) => async (dispatch) => {
    try {
        const client = await ApiClientConnector(instance, accounts);
        const result = await client.getB2BReservationSummary.get(criteria);        
        dispatch(getReservationSummaryList(result));
        return result;
    } catch (error) {
        toast.log(error);

    }
}

export const saveReservations = (instance, accounts, criteria) => async (dispatch) => {    
    try {
        const client = await ApiClientConnector(instance, accounts);
        const result = await client.saveB2BReservations.post(criteria);
        dispatch(setReservationId(result.ReservationId));
        return result.ReservationId;
    } catch (error) {
        toast.error("Reservation save failed:", error);
        throw error;
    }
};

export const fetchReservationById = (instance, accounts, id) => async (dispatch) => {    
    try {
        const client = await ApiClientConnector(instance, accounts);
        const result = await client.getB2BReservationById.get(id);
        return result;
    } catch (error) {
        toast.error("Reservation fetch failed:", error);
        throw error;
    }
};

export default ReservationsManagerSlice.reducer;
import { Divider, Grid, Typography } from '@mui/material';
import dayjs from 'dayjs';
import React from 'react';

const SummarySection = ({ reservation }) => {
	const hotel = reservation?.Hotels?.[0];

	const nights = () => {
		if (!hotel?.CheckIn || !hotel?.CheckOut) return 0;
		const checkInDate = dayjs(hotel?.CheckIn);
		const checkOutDate = dayjs(hotel?.CheckOut);
		return checkOutDate.diff(checkInDate, "day");
	};
	
	return (
		<>
			<Typography variant="body1" fontWeight="bold" mb={2}>Summary</Typography>
			<Grid container spacing={3} mb={3}>
				<Grid item xs>
					<Typography variant="body2" fontWeight="bold" mb={1}>Hotel Name</Typography>
					<Typography variant="body2">{hotel?.PropertyName || "N/A"}</Typography>
				</Grid>
				<Grid item xs={12} sm={2}>
					<Typography variant="body2" fontWeight="bold" mb={1}>Check In</Typography>
					<Typography variant="body2">{hotel?.CheckIn ? new Date(hotel.CheckIn).toLocaleDateString() : "N/A"}</Typography>
				</Grid>
				<Grid item xs={12} sm={2}>
					<Typography variant="body2" fontWeight="bold" mb={1}>Check Out</Typography>
					<Typography variant="body2">{hotel?.CheckOut ? new Date(hotel.CheckOut).toLocaleDateString() : "N/A"}</Typography>
				</Grid>
				<Grid item xs={12} sm={2}>
					<Typography variant="body2" fontWeight="bold" mb={1}>No of Nights</Typography>
					<Typography variant="body2">{nights() || "N/A"}</Typography>
				</Grid>
				<Grid item xs container direction="column" alignItems="flex-end">
					<Typography variant="body2" fontWeight="bold" mb={1}>Hotel Message</Typography>
					<Typography variant="body2">N/A</Typography>
				</Grid>
			</Grid>
			<Divider sx={{ mb: 3 }} />
		</>
	);
};

export default SummarySection;

import { Grid, Typography } from '@mui/material';
import React from 'react';

const ReservationInfo = ({ reservation }) => {
	return (
		<Grid container spacing={3} sx={{ mb: 4 }}>
			{/* Left Section */}
			<Grid item xs={12} sm={6} container direction="column" alignItems="flex-start">
				<Grid container spacing={1} alignItems="center" mb={2}>
					<Grid item>
						<Typography variant="h6" fontWeight="bold">Booking Ref:</Typography>
					</Grid>
					<Grid item>
						<Typography variant="body2">{reservation?.ReservationNumber || "N/A"}</Typography>
					</Grid>
				</Grid>


				<Typography variant="h6" fontWeight="bold" mb={1}>Booking Info</Typography>
				<Typography variant="body2" mb={1}>Status: {reservation?.Status || "N/A"}</Typography>
				<Typography variant="body2" mb={1}>Date: {reservation?.Timestamp ? new Date(reservation.Timestamp).toLocaleDateString() : "N/A"}</Typography>
			</Grid>

			{/* Right Section */}
			<Grid item xs={12} sm={6} container direction="column" alignItems="flex-end">
				<Grid container spacing={1} alignItems="center" justifyContent="end" mb={1}>
					<Grid item>
						<Typography variant="h6" fontWeight="bold" mb={1}>Total Booking Cost:</Typography>
					</Grid>
					<Grid item>
						<Typography variant="body2">
							{reservation?.ClientCurrency} {reservation?.Hotels?.[0]?.ClientNetSell?.Total || "N/A"}
						</Typography>
					</Grid>
				</Grid>

				<Grid container spacing={1} alignItems="center" justifyContent="end" mb={1}>
					<Grid item>
						<Typography variant="h6" fontWeight="bold">Passenger Details:</Typography>
					</Grid>
					<Grid item>
						<Typography variant="body2">Pax Count: {reservation?.PaxCount || 0}</Typography>
					</Grid>
				</Grid>
			</Grid>
		</Grid>
	);
};

export default ReservationInfo;

import { Grid, Typography } from '@mui/material';
import React from 'react';

const SelectionSection = ({ reservation }) => {
    const hotel = reservation?.Hotels?.[0];

    return (
        <>
            <Typography variant="body1" fontWeight="bold" mb={2}>You Selected</Typography>
            <Grid container spacing={3} justifyContent="center">
                {hotel?.Rooms?.length > 0 ? (
                    hotel.Rooms.map((room, index) => (
                        <Grid item xs={12} key={index}>
                            <Grid container spacing={2} justifyContent="space-between">
                                {/* Room Name */}
                                <Grid item xs={12} sm={3} lg={2}>
                                    <Typography variant="body2" fontWeight="bold" mb={1}>Room Name</Typography>
                                    <Typography variant="body2">{room.RoomName || "N/A"}</Typography>
                                </Grid>

                                {/* Room Type */}
                                <Grid item xs={12} sm={3} lg={2}>
                                    <Typography variant="body2" fontWeight="bold" mb={1}>Room Type</Typography>
                                    <Typography variant="body2">{room.RoomType || "N/A"}</Typography>
                                </Grid>

                                {/* Room Count */}
                                <Grid item xs={12} sm={2} lg={2}>
                                    <Typography variant="body2" fontWeight="bold" mb={1}>Room Count</Typography>
                                    <Typography variant="body2">1</Typography>
                                </Grid>

                                {/* Discount */}
                                <Grid item xs={12} sm={2} lg={2}>
                                    <Typography variant="body2" fontWeight="bold" mb={1}>Discount</Typography>
                                    {room?.Discounts?.length > 0 ? (
                                        room.Discounts.map((d, idx) => (
                                            <Typography key={idx} variant="body2">
                                                {d.Name}
                                            </Typography>
                                        ))
                                    ) : (
                                        <Typography variant="body2">N/A</Typography>
                                    )}
                                </Grid>

                                {/* Description */}
                                <Grid item xs={12} sm={2} lg={3}>
                                    <Typography variant="body2" fontWeight="bold" mb={1}>Description</Typography>
                                    {room?.Discounts?.length > 0 ? (
                                        room.Discounts.map((d, idx) => (
                                            <Typography key={idx} variant="body2">
                                                {d.Description}
                                            </Typography>
                                        ))
                                    ) : (
                                        <Typography variant="body2">N/A</Typography>
                                    )}
                                </Grid>

                                {/* Supplements */}
                                <Grid item xs={12} sm={3} lg={1}> {/* Adjusted to left */}
                                    <Typography variant="body2" fontWeight="bold" mb={1}>Supplements</Typography>
                                    {room?.Supplements?.length > 0 ? (
                                        room.Supplements.map((s, idx) => (
                                            <Typography key={idx} variant="body2">
                                                {s.Name}
                                            </Typography>
                                        ))
                                    ) : (
                                        <Typography variant="body2">N/A</Typography>
                                    )}
                                </Grid>
                            </Grid>
                        </Grid>
                    ))
                ) : (
                    <Grid item xs={12}>
                        <Typography variant="body2">No rooms selected.</Typography>
                    </Grid>
                )}
            </Grid>
        </>
    );
};

export default SelectionSection;

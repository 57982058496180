import { Grid, Divider, CircularProgress, Box } from '@mui/material';
import React, { useEffect, useState } from 'react';
import PageContainer from 'src/components/container/PageContainer';
import Breadcrumb from 'src/layouts/full/shared/breadcrumb/Breadcrumb';
import ChildCard from '../../shared/ChildCard';
import { useDispatch } from 'react-redux';
import BackButton from '../../shared/button/BackButton';
import { toast } from 'react-toastify';
import ReservationInfo from './ReservationInfo';
import SummarySection from './SummarySection';
import SelectionSection from './SelectionSection';
import { useParams } from 'react-router';
import { useMsal } from '@azure/msal-react';
import { fetchReservationById } from 'src/store/reservations/ReservationsManagerSlice';

const BCrumb = [
	{ to: '/', title: 'Home' },
	{ title: 'Reservations Overview' },
];

const HotelReservationsOverview = () => {
	const { id } = useParams();
	const dispatch = useDispatch();
	const [reservation, setReservation] = useState(null);
	const [loading, setLoading] = useState(true);
	const { instance, accounts } = useMsal();

	useEffect(() => {
		const fetchData = async () => {
			try {
				setLoading(true);
				const data = await dispatch(fetchReservationById(instance, accounts, id));
				setReservation(data);
			} catch (error) {
				toast.error("Error fetching reservation details: " + error.message);
			} finally {
				setLoading(false);
			}
		};

		if (id) {
			fetchData();
		}
	}, [id, dispatch, instance, accounts]);

	return (
		<PageContainer title="Reservations Overview" description="This is reservations overview">
			{/* Breadcrumb */}
			<Breadcrumb title="Reservations Overview" items={BCrumb} />

			{/* Main Contents */}
			<ChildCard sx={{ p: 3, borderRadius: 2, boxShadow: 1 }}>
				{loading ? (
					<Box display="flex" justifyContent="center" alignItems="center" minHeight="200px">
						<CircularProgress />
					</Box>
				) : (
					<>
						<ReservationInfo reservation={reservation} />
						<Divider mb={3} />
						<SummarySection reservation={reservation} />
						<SelectionSection reservation={reservation} />

						<Grid item xs={12} mt={5}>
							<BackButton to="/reservations/my"/>
						</Grid>
					</>
				)}
			</ChildCard>
		</PageContainer>
	);
};

export default HotelReservationsOverview;

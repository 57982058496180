import { Button } from "@mui/material";
import { IconArrowBack } from "@tabler/icons";
import { useNavigate } from 'react-router-dom';

const BackButton = ({to = null, style = {}, onClick = null}) => {
    const navigate = useNavigate();

    const handleBack = () => {
        if (onClick) {
            onClick();
        }
        if (to) {
            navigate(to);
        } else {
            navigate(-1);
        }
    };

    return(
        <Button
            variant='contained'
            color='error'
            startIcon={<IconArrowBack size={18}/>}
            style={style}
            onClick={handleBack}> 
            Back
        </Button>
    )
}

export default BackButton;
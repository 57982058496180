import HttpClient from "../api/HttpClient";

class OkloReservationsApiClient extends HttpClient {
  constructor(baseURL, token, tenant) {
    super({
      baseURL,
      token,
    });
    this.tenant = tenant;
  }

  get userProfile() {
    return {
      get: () => this.get("user-tenants"),
    };
  }

  get destinations() {
    return {
      get: (prefix) =>
        this.get(`${this.tenant}/lookup/locations?term=${prefix}`),
    };
  }

  get agents() {
    return {
      get: (criteria) => {
        const query = this.createODataQuery(criteria);
        return this.get(`${this.tenant}/agents${query}`);
      },
      post: (agentId, data) => this.post(`${this.tenant}/agents/${agentId}/users`, data),
    };
  }

  get amenities() {
    return {
      get: (criteria) => {
        const query = this.createODataQuery(criteria);
        return this.get(`${this.tenant}/amenities${query}`);
      }
    };
  }

  get locations() {
    return {
      get: (criteria) => {
        return this.get(
          `${this.tenant}/locations${this.createODataQuery(criteria)}`
        );
      },
    };
  }

  get hotels() {
    return {
      post: (criteria) => {
        return this.post(`${this.tenant}/b2b-rates/search/hotels`, criteria);
      },
    };
  }

  get hotelById() {
    return {
      post: (hotelId, criteria) => {
        return this.post(`${this.tenant}/b2b-rates/search/hotels/${hotelId}`, criteria);
      },
    };
  }

  get getB2BReservationSummary() {
    return {
      get: (criteria) => {
        return this.get(
          `${this.tenant}/b2b-reservations${this.createODataQuery(criteria)}`
        );
      },
    };
  }

  get saveB2BReservations() {
    return {
      post: (criteria) => {
        return this.post(`${this.tenant}/b2b-reservations`, criteria);
      },
    };
  }

  get getB2BReservationById() {
    return {
      get: (id) => {
        return this.get(`${this.tenant}/b2b-reservations/${id}`);
      },
    };
  }

  get agentUsers() {
    return {
      get: () => {
        return this.get(`${this.tenant}/lookup/agents/my`);
      },
    };
  }

  createODataQuery = (criteria) => {
    criteria = criteria ?? {};
    const filter = criteria.filter ? `$filter=${criteria.filter}` : "";
    const select = criteria.select ? `$select=${criteria.select}` : "";
    const orderBy = criteria.orderBy ? `$orderby=${criteria.orderBy}` : "";
    const top = criteria.top > 0 ? `$top=${criteria.top}` : "";
    const expand = criteria.expand ? `$expand=${criteria.expand}` : "";
    const apply = criteria.apply ? `$apply=${criteria.apply}` : "";
    let query = "?";
    if (top) {
      query += `${top}&`;
    }
    if (filter) {
      query += `${filter}&`;
    }
    if (select) {
      query += `${select}&`;
    }
    if (orderBy) {
      query += `${orderBy}&`;
    }
    if (expand) {
      query += `${expand}&`;
    }
    if (apply) {
      query += `${apply}&`;
    }
    return query;
  };
}

export default OkloReservationsApiClient;
